import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { useState } from 'react';
import { Button, IconActionSearch, IconGlobalMenu, IconGlobalQrCode, IconNavigationDirection, Space, TapBar, TapBarButton } from '@hse-design/react';
import { Playground, Props } from 'docz';
import { ComponentLinks } from '../common';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "tapbar"
    }}>{`TapBar`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { TapBar, TapBarButton } from '@hse-design/react';
`}</code></pre>
    <ComponentLinks figma={'https://www.figma.com/file/cBHD57QcCt9WDT4e7e2B3w/hse_general_library?node-id=4573%3A15056'} storybook={'/?path=/story/tapbar-tapbar--playground'} name={'TapBar'} vueStorybook={'/?path=/story/tapbar-tapbar--playground'} mdxType="ComponentLinks" />
    <h2 {...{
      "id": "описание"
    }}>{`Описание`}</h2>
    <p>{`TapBar – это компонент навигации в нижней части экрана.
TapBar подходит для использования только на мобильных устройствах (брейкпоинтах `}<inlineCode parentName="p">{`mobile-small`}</inlineCode>{` и `}<inlineCode parentName="p">{`mobile-large`}</inlineCode>{`).`}</p>
    <p>{`TapBar можно отображать вместе с `}<a parentName="p" {...{
        "href": "/src-components-header"
      }}>{`Header`}</a>{` или без него. При использовании TapBar совместно с `}<a parentName="p" {...{
        "href": "/src-components-header"
      }}>{`Header`}</a>{`, важные действия рекомендуется размещать в TapBar. Действия не должны дублироваться в TapBar и `}<a parentName="p" {...{
        "href": "/src-components-header"
      }}>{`Header`}</a>{`.`}</p>
    <p>{`Управлять видимостью компонента можно с помощью пропа `}<inlineCode parentName="p">{`isActive`}</inlineCode>{`. По умолчанию компонент видимый.`}</p>
    <p>{`Компонент полностью контролируется извне. Для добавления кнопок навигации, передайте в `}<inlineCode parentName="p">{`children`}</inlineCode>{` необходимое количество кнопок `}<inlineCode parentName="p">{`TapBarButton`}</inlineCode>{`. Пример использования `}<a parentName="p" {...{
        "href": "#%D0%BA%D0%BD%D0%BE%D0%BF%D0%BA%D0%B8-%D0%BD%D0%B0%D0%B2%D0%B8%D0%B3%D0%B0%D1%86%D0%B8%D0%B8"
      }}>{`можно посмотреть ниже`}</a></p>
    <Playground __position={1} __code={'() => {\n  const [activeItem, setActiveItem] = useState(1)\n  const items = [\n    {\n      id: 1,\n      icon: IconGlobalMenu,\n      name: \'Меню\',\n    },\n    {\n      id: 2,\n      icon: IconActionSearch,\n      name: \'Поиск\',\n    },\n    {\n      id: 3,\n      icon: IconNavigationDirection,\n      name: \'Маршрут\',\n    },\n    {\n      id: 4,\n      icon: IconGlobalQrCode,\n      name: \'QR-сканер\',\n      disabled: true,\n    },\n  ]\n  return (\n    <TapBar style={{ position: \'relative\', maxWidth: \'375px\' }}>\n      {items.map(({ id, name, icon, disabled = false }) => (\n        <TapBarButton\n          key={id}\n          disabled={disabled}\n          active={activeItem === id}\n          icon={icon}\n          onClick={() => setActiveItem(id)}\n        >\n          {name}\n        </TapBarButton>\n      ))}\n    </TapBar>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      Button,
      IconActionSearch,
      IconGlobalMenu,
      IconGlobalQrCode,
      IconNavigationDirection,
      Space,
      TapBar,
      TapBarButton,
      Playground,
      Props,
      ComponentLinks,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [activeItem, setActiveItem] = useState(1);
        const items = [{
          id: 1,
          icon: IconGlobalMenu,
          name: 'Меню'
        }, {
          id: 2,
          icon: IconActionSearch,
          name: 'Поиск'
        }, {
          id: 3,
          icon: IconNavigationDirection,
          name: 'Маршрут'
        }, {
          id: 4,
          icon: IconGlobalQrCode,
          name: 'QR-сканер',
          disabled: true
        }];
        return <TapBar style={{
          position: "relative",
          maxWidth: "375px"
        }} mdxType="TapBar">
        {items.map(({
            id,
            name,
            icon,
            disabled = false
          }) => <TapBarButton key={id} disabled={disabled} active={activeItem === id} icon={icon} onClick={() => setActiveItem(id)} mdxType="TapBarButton">
            {name}
          </TapBarButton>)}
      </TapBar>;
      }}
    </Playground>
    <h2 {...{
      "id": "примеры"
    }}>{`Примеры`}</h2>
    <h3 {...{
      "id": "кнопки-навигации"
    }}>{`Кнопки навигации`}</h3>
    <p>{`Компонент полностью контролируется извне. Для добавления кнопок навигации, передайте в `}<inlineCode parentName="p">{`children`}</inlineCode>{` необходимое количество кнопок `}<inlineCode parentName="p">{`TapBarButton`}</inlineCode>{`.`}</p>
    <p>{`Кнопку `}<inlineCode parentName="p">{`TapBarButton`}</inlineCode>{` можно сделать неактивной, передав проп `}<inlineCode parentName="p">{`disabled`}</inlineCode>{`. Для задания активного состояния кнопки, используйте проп `}<inlineCode parentName="p">{`active`}</inlineCode></p>
    <Playground __position={2} __code={'() => {\n  const [activeItem, setActiveItem] = useState(1)\n  const items = [\n    {\n      id: 1,\n      icon: IconGlobalMenu,\n      name: \'Меню\',\n    },\n    {\n      id: 2,\n      icon: IconActionSearch,\n      name: \'Поиск\',\n    },\n    {\n      id: 3,\n      icon: IconNavigationDirection,\n      name: \'Маршрут\',\n    },\n    {\n      id: 4,\n      icon: IconGlobalQrCode,\n      name: \'QR-сканер\',\n      disabled: true,\n    },\n  ]\n  return (\n    <TapBar style={{ position: \'relative\', maxWidth: \'375px\' }}>\n      {items.map(({ id, name, icon, disabled = false }) => (\n        <TapBarButton\n          key={id}\n          disabled={disabled}\n          active={activeItem === id}\n          icon={icon}\n          onClick={() => setActiveItem(id)}\n        >\n          {name}\n        </TapBarButton>\n      ))}\n    </TapBar>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      Button,
      IconActionSearch,
      IconGlobalMenu,
      IconGlobalQrCode,
      IconNavigationDirection,
      Space,
      TapBar,
      TapBarButton,
      Playground,
      Props,
      ComponentLinks,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [activeItem, setActiveItem] = useState(1);
        const items = [{
          id: 1,
          icon: IconGlobalMenu,
          name: 'Меню'
        }, {
          id: 2,
          icon: IconActionSearch,
          name: 'Поиск'
        }, {
          id: 3,
          icon: IconNavigationDirection,
          name: 'Маршрут'
        }, {
          id: 4,
          icon: IconGlobalQrCode,
          name: 'QR-сканер',
          disabled: true
        }];
        return <TapBar style={{
          position: "relative",
          maxWidth: "375px"
        }} mdxType="TapBar">
        {items.map(({
            id,
            name,
            icon,
            disabled = false
          }) => <TapBarButton key={id} disabled={disabled} active={activeItem === id} icon={icon} onClick={() => setActiveItem(id)} mdxType="TapBarButton">
            {name}
          </TapBarButton>)}
      </TapBar>;
      }}
    </Playground>
    <h3 {...{
      "id": "видимость-компонента"
    }}>{`Видимость компонента`}</h3>
    <p>{`Управлять видимостью компонента можно с помощью пропа `}<inlineCode parentName="p">{`isActive`}</inlineCode>{`. По умолчанию компонент видимый`}</p>
    <Playground __position={3} __code={'() => {\n  const [isActive, setIsActive] = useState(true)\n  const [activeItem, setActiveItem] = useState(1)\n  const items = [\n    {\n      id: 1,\n      icon: IconGlobalMenu,\n      name: \'Меню\',\n    },\n    {\n      id: 2,\n      icon: IconActionSearch,\n      name: \'Поиск\',\n    },\n    {\n      id: 3,\n      icon: IconNavigationDirection,\n      name: \'Маршрут\',\n    },\n    {\n      id: 4,\n      icon: IconGlobalQrCode,\n      name: \'QR-сканер\',\n      disabled: true,\n    },\n  ]\n  return (\n    <>\n      <Button onClick={() => setIsActive(!isActive)}>\n        {isActive ? \'Hide\' : \'Show\'}\n      </Button>\n      <Space size={Space.Size.small} vertical />\n      <TapBar\n        isActive={isActive}\n        style={{ position: \'relative\', maxWidth: \'375px\' }}\n      >\n        {items.map(({ id, name, icon, disabled = false }) => (\n          <TapBarButton\n            key={id}\n            disabled={disabled}\n            active={activeItem === id}\n            icon={icon}\n            onClick={() => setActiveItem(id)}\n          >\n            {name}\n          </TapBarButton>\n        ))}\n      </TapBar>\n    </>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      Button,
      IconActionSearch,
      IconGlobalMenu,
      IconGlobalQrCode,
      IconNavigationDirection,
      Space,
      TapBar,
      TapBarButton,
      Playground,
      Props,
      ComponentLinks,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [isActive, setIsActive] = useState(true);
        const [activeItem, setActiveItem] = useState(1);
        const items = [{
          id: 1,
          icon: IconGlobalMenu,
          name: 'Меню'
        }, {
          id: 2,
          icon: IconActionSearch,
          name: 'Поиск'
        }, {
          id: 3,
          icon: IconNavigationDirection,
          name: 'Маршрут'
        }, {
          id: 4,
          icon: IconGlobalQrCode,
          name: 'QR-сканер',
          disabled: true
        }];
        return <>
        <Button onClick={() => setIsActive(!isActive)} mdxType="Button">{isActive ? 'Hide' : 'Show'}</Button>
        <Space size={Space.Size.small} vertical mdxType="Space" />
        <TapBar isActive={isActive} style={{
            position: "relative",
            maxWidth: "375px"
          }} mdxType="TapBar">
          {items.map(({
              id,
              name,
              icon,
              disabled = false
            }) => <TapBarButton key={id} disabled={disabled} active={activeItem === id} icon={icon} onClick={() => setActiveItem(id)} mdxType="TapBarButton">
              {name}
            </TapBarButton>)}
        </TapBar>
      </>;
      }}
    </Playground>
    <h2 {...{
      "id": "пропы"
    }}>{`Пропы`}</h2>
    <Props of={TapBar} mdxType="Props" />
    <h2 {...{
      "id": "пропы-tapbarbutton"
    }}>{`Пропы TapBarButton`}</h2>
    <Props of={TapBarButton} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      